// Translated
// Migrated
<template lang="pug">
div(ref="container")
  slot
</template>

<script>
class Scroller {
  resetAnimation () {
    if (!this.movingPart) {
      return
    }

    const { children: movingPartChildren } = this.movingPart

    if (this.config.direction === 'left') {
      const elementOutsideView = movingPartChildren[0]
      this.movingPart.appendChild(elementOutsideView.cloneNode(true))
      this.movingPart.removeChild(elementOutsideView)
    } else {
      const elementOutsideView = movingPartChildren[movingPartChildren.length - 1]
      this.movingPart.prepend(elementOutsideView.cloneNode(true))
      this.movingPart.removeChild(elementOutsideView)
    }

    this.makeAnimation()
  }

  makeAnimation (startValue) {
    if (!this.movingPart?.children?.length) {
      return
    }

    const { offsetWidth: firstElementWidth } = this.movingPart.children[0]

    const startPx = startValue ?? (this.config.direction === 'left' ? 0 : 0 - firstElementWidth)
    const endPx = this.config.direction === 'left' ? 0 - firstElementWidth : 0

    this.movingPart.style.transform = `translateX(${startPx}px)`
    this.movingPart.style.transition = 'none'

    const animationDuration = 1000 * Math.abs(endPx - startPx) / this.config.speed

    this.callAfterDomUpdate(() => {
      if (!this.movingPart) {
        return
      }

      this.movingPart.style.transform = `translateX(${endPx}px)`
      this.movingPart.style.transition = `transform ${animationDuration}ms ${this.config.animation}`

      // When animation is finished: move element to the end of the line (which end depends on direction) & reset animation
      this.nextAnimationResetTimeout = window.window.setTimeout(
        () => this.resetAnimation(),
        animationDuration + this.config.delayBetweenAnimationsMS
      )
    })
  }

  /**
   * Restore target element to state before scroller
   * Can't be started again once restored without creating a new instance
   */
  restore () {
    clearTimeout(this.nextAnimationResetTimeout)
    this.targetResizeObserver.disconnect()

    Object.values(this.initialMovingPart.children).forEach(
      item => this.targetElement.appendChild(item)
    )

    if (this.movingPart) {
      this.targetElement.removeChild(this.movingPart)
    }
  }

  init (initialTotalWidthPx, largestElementPx) {
    if (this.movingPart) {
      clearTimeout(this.nextAnimationResetTimeout)
      this.targetElement.removeChild(this.movingPart)
    }

    this.movingPart = this.initialMovingPart.cloneNode(true)
    this.movingPart.style.cssText = 'left:unset;transition:none;position:relative;white-space:nowrap'

    this.targetElement.appendChild(this.movingPart)

    /**
     * If the total width of all items in movingPart div is less than parent div
     * then append clones of items until div is filled
     */
    let elementsTotalWidthPx = initialTotalWidthPx
    for (
      let index = 0;
      elementsTotalWidthPx <= this.targetElement.offsetWidth + largestElementPx;
      index++
    ) {
      const element = this.movingPart.children[index]

      const clone = element.cloneNode(true)
      this.movingPart.appendChild(clone)

      elementsTotalWidthPx += element.offsetWidth
    }

    this.callAfterDomUpdate(() => this.makeAnimation())
  }

  constructor (target, config) {
    if (!target.children.length) {
      throw new Error('Target element empty')
    }

    this.targetElement = target
    this.targetElement.style.overflow = 'hidden'

    this.config = config

    /**
     * Move elements from target element to initialMovingPart & get initialTotalWidthPx and largestElementPx
     * used when initalizing to calculate how many elements are required to fill target element width
     */
    this.initialMovingPart = document.createElement('div')
    let initialTotalWidthPx = 0
    let largestElementPx = 0
    Object.values(this.targetElement.children).forEach((element) => {
      const { offsetWidth: currentElementWidthPx } = element

      if (currentElementWidthPx > largestElementPx) {
        largestElementPx = currentElementWidthPx
      }

      const clone = element.cloneNode(true)
      this.initialMovingPart.appendChild(clone)
      this.targetElement.removeChild(element)

      initialTotalWidthPx += currentElementWidthPx
    })

    this.callAfterDomUpdate(() => this.init(initialTotalWidthPx, largestElementPx))

    // Re-initalize on target element *width* change
    this.prevTargetWidthPx = this.targetElement.offsetWidth
    let resizeThrottleTimeout
    this.targetResizeObserver = new ResizeObserver(() => {
      const { offsetWidth: targetWidthPx } = this.targetElement

      if (this.prevTargetWidthPx !== targetWidthPx) {
        this.prevTargetWidthPx = targetWidthPx

        clearTimeout(resizeThrottleTimeout)

        resizeThrottleTimeout = window.window.setTimeout(
          () => this.init(initialTotalWidthPx, largestElementPx)
          , 250
        )
      }
    })
    this.targetResizeObserver.observe(this.targetElement)
  }

  callAfterDomUpdate (fn) {
    const intermediate = () => window.requestAnimationFrame(fn)
    window.requestAnimationFrame(intermediate)
  }
}

export default defineNuxtComponent({
  props: {
    speed: {
      type: Number,
      default: 20,
    },

    animation: {
      type: String,
      default: 'linear',
    },

    direction: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right'].includes(value),
    },

    finishAnimationBeforePause: {
      type: Boolean,
      default: false,
    },

    delayBetweenAnimationsMS: {
      type: Number,
      default: 0,
    },
  },

  setup (props) {
    const container = ref(null)

    return {
      props,
      container,
    }
  },

  watch: {
    $props: {
      handler () {
        this.resetScroller()
      },

      deep: true,
    },
  },

  beforeUnmount () {
    if (this.scrollerInstance) {
      this.scrollerInstance.restore()
    }
  },

  mounted () {
    this.initScroller()
  },

  methods: {
    initScroller () {
      this.scrollerInstance = new Scroller(this.container, this.props)
    },

    resetScroller () {
      if (this.scrollerInstance) {
        this.scrollerInstance.restore()
      }
      this.scrollerInstance = new Scroller(this.container, this.props)
    },
  },
})
</script>
