// Translated
// Migrated
<template lang="pug">
.position-relative.w-100.py-3.my-1.review-scroller
  Scroller.d-flex.flex-row.overflow-hidden(v-if="reviews.length !== 0" :speed="10")
    nuxt-link.d-inline-flex.flex-column.pr-4.review-scroller-item(
      v-for="(review, i) in reviews"
      :key="`r-${i}`"
      :to="`/${review?.url_slug}`"
    )
      span.text-truncate.text-black
        .d-inline-block.mr-2(
          style="transform: translateY(-5px)"
        )
          Stars(
            :score="review?.rating"
            type="inline"
          )
        span {{ review?.text }}
      span.text-truncate {{ localeDateFormat(review?.created_at, 'YYYY/MM/DD') }} - {{ review?.Resnamn }}
</template>

<script setup>
defineProps({
  reviews: {
    type: Array,
    default: () => [],
  },
})

const { localeDateFormat } = useDate()
</script>

<style lang="scss" scoped>
.review-scroller {
  &::after {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(
      90deg,
      rgba(255,255,255,1) 0%,
      rgba(255,255,255,0) 5%,
      rgba(255,255,255,0) 95%,
      rgba(255,255,255,1) 100%
    );
  }

  &-item {
    max-width: 400px;
  }
}
</style>
